import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import MuiMenuItem from '@mui/material/MenuItem'
import { useCallback } from 'react'
import MuiFormControl from '@mui/material/FormControl/FormControl'
import { FormHelperText } from '@mui/material'
import { useDeviceType } from '../../../hooks/useDeviceType'

export type SelectItem = {
  value?: string
  label: string
}

type SelectSize = 'xs' | 'sm' | 'sm2' | 'md' | 'lg'

type SelectProps = {
  value?: string | null
  items: SelectItem[]
  label?: string
  size?: SelectSize
  onChange?: (_value: string) => void
  error?: string
  disabled?: boolean
}

const width = (pc: boolean, size?: SelectSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'sm2':
      return 200
    case 'md':
      return pc ? 360 : 240
    case 'lg':
      return pc ? 480 : 240
    default:
      return '100%'
  }
}

export default function SelectString({ value, items, label, size = 'md', error, disabled, onChange }: SelectProps) {
  const { pc } = useDeviceType()
  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      if (onChange) {
        onChange(e.target.value)
      }
    },
    [onChange]
  )
  return (
    <MuiFormControl error={error !== undefined}>
      <MuiSelect
        value={value ?? ''}
        label={label}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{ width: width(pc, size) }}
        disabled={disabled}
        displayEmpty
      >
        {items.map((item) => (
          <MuiMenuItem key={item.value} value={item.value}>
            {item.label}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </MuiFormControl>
  )
}
