import MuiTreeItem from '@mui/lab/TreeItem'
import React, { ReactElement, ReactNode, useCallback } from 'react'
import MuiBox from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { Component } from '../../../types/react'

type TreeItemProps = {
  id: string
  icon?: ReactElement
  label: ReactNode
  end?: Component
  href?: string
  minWidth?: string
  children?: ReactNode
}

function LinkedTreeItem({ id, icon, label, end, href, minWidth, children }: TreeItemProps) {
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    if (href) {
      navigate(href)
    }
  }, [href, navigate])
  return (
    <MuiTreeItem
      nodeId={id}
      label={
        <MuiBox sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1, my: 1 }}>
          <MuiBox
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1, wordBreak: 'break-all' }}
          >
            <MuiBox sx={{ mr: 1 }}>{icon}</MuiBox>
            {label}
          </MuiBox>
          {end}
        </MuiBox>
      }
      onClick={handleClick}
      sx={{ minWidth, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      {children}
    </MuiTreeItem>
  )
}

export default function TreeItem({ id, icon, label, end, href, minWidth, children }: TreeItemProps) {
  if (href) {
    return (
      <LinkedTreeItem id={id} icon={icon} label={label} end={end} href={href} minWidth={minWidth}>
        {children}
      </LinkedTreeItem>
    )
  }
  return (
    <MuiTreeItem
      nodeId={id}
      label={
        <MuiBox sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, my: 1 }}>
          <MuiBox
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1, wordBreak: 'break-all' }}
          >
            <MuiBox sx={{ mr: 1 }}>{icon}</MuiBox>
            {label}
          </MuiBox>
          {end}
        </MuiBox>
      }
      sx={{ minWidth, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      {children}
    </MuiTreeItem>
  )
}
