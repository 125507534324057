import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import FormItem from '../../components/molecules/inputs/FormItem'
import TextField from '../../components/atoms/inputs/TextField'
import ConstructionTypeSelect from '../../components/organisms/job/ConstructionTypeSelect'
import NumberControl from '../../components/atoms/inputs/NumberControl'
import Label from '../../components/atoms/display/Label'
import Button from '../../components/atoms/inputs/Button'
import useValidationRule from '../../hooks/useValidationRule'
import Form from '../../components/atoms/inputs/Form'
import { JobActions } from '../../store/job'
import usePath from '../../hooks/usePath'
import { JobSelectors } from '../../store/job/selectors'
import useRouter from '../../hooks/useRouter'

type JobFormParams = {
  establishedYear?: number | null
  numberOfEmployees: number
  constructionType1: string
  constructionType1Detail: string
  constructionType2: string | null
  constructionType2Detail: string | null
  constructionType3: string | null
  constructionType3Detail: string | null
  siteUrl?: string | null
  lineId: string
}

function blankToNull(value?: string | null) {
  if (value === null || value === undefined) {
    return null
  }
  if (value.length > 0) {
    return value
  }
  return null
}

export default function JobEditPage() {
  const { changeTitle } = usePage()
  const rules = useValidationRule()
  const dispatch = useDispatch()
  const { job: jobPage } = usePath()
  const { job: toJob } = useRouter()
  const job = useSelector(JobSelectors.job)
  const [initialize, setInitialize] = useState(false)

  const form = useForm<JobFormParams>({
    resolver: yupResolver(rules.job),
    mode: 'onChange',
  })
  const { errors } = form.formState

  useEffect(() => {
    changeTitle('求人管理', '企業詳細編集')
  }, [changeTitle])

  useEffect(() => {
    dispatch(JobActions.fetchJob())
  }, [dispatch])

  useEffect(() => {
    if (job) {
      form.setValue('establishedYear', job.establishedYear)
      form.setValue('numberOfEmployees', job.numberOfEmployees)
      form.setValue('constructionType1', job.constructionType1)
      form.setValue('constructionType1Detail', job.constructionType1Detail)
      form.setValue('constructionType2', job.constructionType2)
      form.setValue('constructionType2Detail', job.constructionType2Detail)
      form.setValue('constructionType3', job.constructionType3)
      form.setValue('constructionType3Detail', job.constructionType3Detail)
      form.setValue('siteUrl', job.siteUrl)
      form.setValue('lineId', job.lineId)
    }
    setInitialize(true)
  }, [form, job])

  useEffect(() => {
    form.watch((values, { name }) => {
      if (name === 'constructionType1' && values.constructionType1 === '') {
        form.setValue('constructionType1Detail', '')
      }
      if (name === 'constructionType2' && values.constructionType2 === '') {
        form.setValue('constructionType2Detail', '')
      }
      if (name === 'constructionType3' && values.constructionType3 === '') {
        form.setValue('constructionType3Detail', '')
      }
    })
  }, [form])

  const handleSave = useCallback(
    (params: JobFormParams) => {
      if (job) {
        dispatch(
          JobActions.updateJob({
            establishedYear: params.establishedYear ?? null,
            numberOfEmployees: params.numberOfEmployees,
            constructionType1: params.constructionType1,
            constructionType1Detail: params.constructionType1Detail,
            constructionType2: params.constructionType2,
            constructionType2Detail: blankToNull(params.constructionType2Detail),
            constructionType3: params.constructionType3,
            constructionType3Detail: blankToNull(params.constructionType3Detail),
            siteUrl: params.siteUrl ?? null,
            lineId: params.lineId,
            getRedirectPath: jobPage,
          })
        )
      } else {
        dispatch(
          JobActions.createJob({
            establishedYear: params.establishedYear ?? null,
            numberOfEmployees: params.numberOfEmployees,
            constructionType1: params.constructionType1,
            constructionType1Detail: params.constructionType1Detail,
            constructionType2: params.constructionType2,
            constructionType2Detail: blankToNull(params.constructionType2Detail),
            constructionType3: params.constructionType3,
            constructionType3Detail: blankToNull(params.constructionType3Detail),
            siteUrl: params.siteUrl ?? null,
            lineId: params.lineId,
            getRedirectPath: jobPage,
          })
        )
      }
    },
    [dispatch, jobPage, job]
  )

  if (!initialize) {
    return null
  }

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSave)}>
        <FlexColumn>
          <FormItem label="設立年">
            <FlexRow align="center">
              <NumberControl
                control={form.control}
                name="establishedYear"
                size="sm"
                error={errors.establishedYear?.message}
              />
              <Label text="年" />
            </FlexRow>
          </FormItem>
          <FormItem label="従業員数">
            <FlexRow align="center">
              <NumberControl
                control={form.control}
                name="numberOfEmployees"
                size="sm"
                error={errors.numberOfEmployees?.message}
              />
              <Label text="人" />
            </FlexRow>
          </FormItem>
          <FormItem label="第1工種">
            <FlexRow>
              <ConstructionTypeSelect name="constructionType1" error={errors.constructionType1?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('constructionType1Detail')}
                error={errors.constructionType1Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="第2工種">
            <FlexRow>
              <ConstructionTypeSelect name="constructionType2" error={errors.constructionType2?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('constructionType2Detail')}
                error={errors.constructionType2Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="第3工種">
            <FlexRow>
              <ConstructionTypeSelect name="constructionType3" error={errors.constructionType3?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('constructionType3Detail')}
                error={errors.constructionType3Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="ホームページURL">
            <TextField size="lg" register={form.register('siteUrl')} error={errors.siteUrl?.message} />
          </FormItem>
          <FormItem label="LINE ID">
            <TextField size="sm" register={form.register('lineId')} error={errors.lineId?.message} />
          </FormItem>
          <FlexRow align="center">
            <Button onClick={toJob} color="secondary">
              キャンセル
            </Button>
            <Button type="submit">保存</Button>
          </FlexRow>
        </FlexColumn>
      </Form>
    </FormProvider>
  )
}
