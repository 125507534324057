import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { JobSelectors } from '../../../store/job/selectors'

export default function AreaView() {
  const workLocations = useSelector(JobSelectors.workLocations)
  return (
    <FlexColumn wrap>
      {workLocations.map((location) => (
        <FlexRow key={location.id}>
          {location.parentArea && <Label text={location.parentArea.name} />}
          <Label text={location.area.name} />
        </FlexRow>
      ))}
    </FlexColumn>
  )
}
