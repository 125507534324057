import { ChatMessage, MoneyspaceChatMessage, ReservedMessage } from '../types/Chat'
import { Request } from './Request'
import { Page } from '../types/System'

export default class ChatRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadMessages(moneyspaceId: string): Promise<ChatMessage[]> {
    const response = await this.request.get<{ messages: ChatMessage[] }>(
      `/moneyspaces/${moneyspaceId}/messages`,
      undefined,
      undefined,
      true
    )
    return response.data.messages
  }

  async loadUnreadMessages(page: number) {
    const response = await this.request.get<Page<MoneyspaceChatMessage>>(`/messages`, { unread: true, page })
    return response.data
  }

  async loadUnreadMessagesNoPage(silent?: boolean) {
    const response = await this.request.get<{ messages: MoneyspaceChatMessage[] }>(
      `/messages`,
      { unread: true },
      undefined,
      silent
    )
    return response.data
  }

  async postMessage(moneyspaceId: string, description: string, destinations: string[]): Promise<ChatMessage> {
    const response = await this.request.post<ChatMessage>(`/moneyspaces/${moneyspaceId}/messages`, {
      description,
      destinations,
    })
    return response.data
  }

  async readMessage(moneyspaceId: string, messageId: string) {
    await this.request.put(`/moneyspaces/${moneyspaceId}/messages/${messageId}/read`, undefined, true)
  }

  async postMessages(moneyspaceIds: string[], description: string): Promise<ChatMessage> {
    const response = await this.request.post<ChatMessage>(`/send-bulk-messages`, {
      msIds: moneyspaceIds,
      description,
    })
    return response.data
  }

  async postMessageSettings(
    companyId: string,
    moneyspaceIds: string[],
    description: string,
    scheduleExpression: string
  ) {
    await this.request.post(`/companies/${companyId}/reserved-messages`, {
      msIds: moneyspaceIds,
      description,
      scheduleExpression,
    })
  }

  async updateMessageSettings(
    reservedMessageId: string,
    companyId: string,
    moneyspaceIds: string[],
    description: string,
    scheduleExpression: string
  ) {
    await this.request.put(`/companies/${companyId}/reserved-messages/${reservedMessageId}`, {
      msIds: moneyspaceIds,
      description,
      scheduleExpression,
    })
  }

  async deleteMessageSettings(reservedMessageId: string, companyId: string) {
    await this.request.delete(`/companies/${companyId}/reserved-messages/${reservedMessageId}`)
  }

  async getReservedMessages(companyId: string) {
    const response = await this.request.get<{ reservedMessages: ReservedMessage[] }>(
      `/companies/${companyId}/reserved-messages`
    )
    return response.data.reservedMessages
  }
}
