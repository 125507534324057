import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useJobWorkLocationSettingDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { JobSelectors } from '../../../store/job/selectors'
import Checkbox from '../../atoms/inputs/Checkbox'
import { JobActions } from '../../../store/job'
import TreeView from '../../atoms/navigation/TreeView'
import TreeItem from '../../atoms/navigation/TreeItem'
import { FlexColumn } from '../../atoms/layout/Flex'

export default function JobWorkLocationSettingDialog() {
  const { state, close } = useJobWorkLocationSettingDialog()
  const areas = useSelector(JobSelectors.areas)
  const workLocations = useSelector(JobSelectors.workLocations)
  const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (workLocations) {
      setSelectedAreaIds(workLocations.map((workLocation) => workLocation.area.id))
    }
  }, [workLocations])

  const handleCheck = useCallback((areaId: string, checked) => {
    if (checked) {
      setSelectedAreaIds([areaId])
    } else {
      setSelectedAreaIds([])
    }
  }, [])

  const handleSave = useCallback(() => {
    dispatch(JobActions.updateWorkLocations({ areaIds: selectedAreaIds }))
  }, [dispatch, selectedAreaIds])

  return (
    <Dialog
      open={state !== undefined}
      title="勤務地設定"
      width="xs"
      actions={<Button onClick={handleSave}>保存</Button>}
      onClose={close}
    >
      <FlexColumn maxHeight="calc(100vh - 256px)">
        <TreeView>
          {areas.map((area) => (
            <TreeItem key={area.id} id={area.id} label={area.name}>
              {area.children.map((child) => (
                <TreeItem
                  key={child.id}
                  id={child.id}
                  label={
                    <Checkbox
                      key={child.id}
                      label={child.name}
                      checked={selectedAreaIds.includes(child.id)}
                      onChange={(checked) => handleCheck(child.id, checked)}
                    />
                  }
                />
              ))}
            </TreeItem>
          ))}
        </TreeView>
      </FlexColumn>
    </Dialog>
  )
}
