import MuiTreeView from '@mui/lab/TreeView'
import React, { ReactNode, useCallback } from 'react'
import { ChevronRight, ExpandMore } from '@mui/icons-material'

type TreeViewProps = {
  selectedId?: string
  expanded?: string[]
  children: ReactNode
  onSelect?: (_value: string) => void
}

export default function TreeView({ selectedId = '', expanded, children, onSelect }: TreeViewProps) {
  const handleSelect = useCallback(
    (event: React.SyntheticEvent, nodeId: string) => {
      if (onSelect) {
        onSelect(nodeId)
      }
    },
    [onSelect]
  )

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeSelect={handleSelect}
      selected={selectedId}
      sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}
    >
      {children}
    </MuiTreeView>
  )
}
