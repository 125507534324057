import { createSlice } from '@reduxjs/toolkit'
import { ChatMessage, ChatRoom, MoneyspaceChatMessage, ReservedMessage } from '../../types/Chat'
import { createAsyncAction } from '../actions'
import { Page } from '../../types/System'

export type ChatState = {
  rooms: ChatRoom[]
  unreadMessages: Page<MoneyspaceChatMessage>
  fetchedUnreadMessages: MoneyspaceChatMessage[]
  reservedMessages: ReservedMessage[]
}

export const initialChatState: ChatState = {
  rooms: [],
  unreadMessages: {
    count: 1,
    results: [],
    next: null,
    previous: null,
  },
  fetchedUnreadMessages: [],
  reservedMessages: [],
}

export const ChatActions = {
  fetchMessages: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
      messages: ChatMessage[]
    }
  >('fetchMessages', async (params, { chatRepository }) => {
    const messages = await chatRepository.loadMessages(params.moneyspaceId)
    return {
      moneyspaceId: params.moneyspaceId,
      messages,
    }
  }),
  loadUnreadMessages: createAsyncAction<{ page: number }, { messages: Page<MoneyspaceChatMessage> }>(
    'loadUnreadMessages',
    async (params, { chatRepository }) => {
      const messages = await chatRepository.loadUnreadMessages(params.page)
      return { messages }
    }
  ),
  fetchUnreadMessages: createAsyncAction<{}, { messages: MoneyspaceChatMessage[] }>(
    'fetchUnreadMessages',
    async (params, { chatRepository }) => {
      const response = await chatRepository.loadUnreadMessagesNoPage(true)
      return {
        messages: response.messages,
      }
    }
  ),
  postMessage: createAsyncAction<
    {
      moneyspaceId: string
      destinations: string[]
      description: string
    },
    {
      moneyspaceId: string
      message: ChatMessage
    }
  >('postMessage', async (params, { chatRepository }, state) => {
    if (state.session.user === undefined) {
      return Promise.reject()
    }
    const message = await chatRepository.postMessage(params.moneyspaceId, params.description, params.destinations)
    return {
      moneyspaceId: params.moneyspaceId,
      message,
    }
  }),
  readMessages: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
    }
  >('readMessages', async (params, { chatRepository }) => {
    const messages = await chatRepository.loadMessages(params.moneyspaceId)
    const promises: Promise<void>[] = []
    messages
      .filter((message) => !message.isRead)
      .forEach((message) => promises.push(chatRepository.readMessage(params.moneyspaceId, message.id)))
    await Promise.all(promises)
    return { moneyspaceId: params.moneyspaceId }
  }),
  postMessages: createAsyncAction<
    {
      moneyspaceIds: string[]
      description: string
    },
    {
      moneyspaceIds: string[]
      message: ChatMessage
    }
  >('postMessages', async (params, { chatRepository }, state) => {
    if (state.session.user === undefined) {
      return Promise.reject()
    }
    const message = await chatRepository.postMessages(params.moneyspaceIds, params.description)
    return {
      moneyspaceIds: params.moneyspaceIds,
      message,
    }
  }),
  postReservedMessage: createAsyncAction<
    {
      moneyspaceIds: string[]
      description: string
      scheduleExpression: string
    },
    {
      repeat: boolean
    }
  >('postReservedMessage', async (params, { chatRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await chatRepository.postMessageSettings(
        companyId,
        params.moneyspaceIds,
        params.description,
        params.scheduleExpression
      )
    }
    return {
      repeat: params.scheduleExpression.startsWith('cron'),
    }
  }),
  updateReservedMessage: createAsyncAction<
    {
      reservedMessageId: string
      moneyspaceIds: string[]
      description: string
      scheduleExpression: string
    },
    {
      repeat: boolean
      reservedMessages: ReservedMessage[]
    }
  >('updateReservedMessage', async (params, { chatRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await chatRepository.updateMessageSettings(
        params.reservedMessageId,
        companyId,
        params.moneyspaceIds,
        params.description,
        params.scheduleExpression
      )
      const reservedMessages = await chatRepository.getReservedMessages(companyId)
      return {
        repeat: params.scheduleExpression.startsWith('cron'),
        reservedMessages,
      }
    }
    return {
      repeat: params.scheduleExpression.startsWith('cron'),
      reservedMessages: [],
    }
  }),
  deleteReservedMessage: createAsyncAction<
    {
      reservedMessageId: string
    },
    {
      reservedMessages: ReservedMessage[]
    }
  >('deleteReservedMessage', async (params, { chatRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await chatRepository.deleteMessageSettings(params.reservedMessageId, companyId)
      const reservedMessages = await chatRepository.getReservedMessages(companyId)
      return {
        reservedMessages,
      }
    }
    return {
      reservedMessages: [],
    }
  }),
  loadReservedMessages: createAsyncAction<
    void,
    {
      reservedMessages: ReservedMessage[]
    }
  >('loadReservedMessages', async (params, { chatRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      const reservedMessages = await chatRepository.getReservedMessages(companyId)
      return { reservedMessages }
    }
    return {
      reservedMessages: [],
    }
  }),
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ChatActions.fetchMessages.fulfilled, (state, action) => {
      const room = state.rooms.find((item) => item.moneyspaceId === action.payload.moneyspaceId)
      if (room) {
        room.messages = action.payload.messages
      } else {
        state.rooms.push({
          moneyspaceId: action.payload.moneyspaceId,
          messages: action.payload.messages,
        })
      }
    })
    builder.addCase(ChatActions.loadUnreadMessages.fulfilled, (state, action) => {
      state.unreadMessages = action.payload.messages
    })
    builder.addCase(ChatActions.fetchUnreadMessages.fulfilled, (state, action) => {
      state.fetchedUnreadMessages = action.payload.messages
    })
    builder.addCase(ChatActions.postMessage.fulfilled, (state, action) => {
      const room = state.rooms.find((item) => item.moneyspaceId === action.payload.moneyspaceId)
      if (room !== undefined) {
        room.messages.push(action.payload.message)
      }
    })
    builder.addCase(ChatActions.readMessages.fulfilled, (state, action) => {
      const room = state.rooms.find((item) => item.moneyspaceId === action.payload.moneyspaceId)
      if (room !== undefined) {
        room.messages.map((message) => ({
          ...message,
          isRead: true,
        }))
      }
    })
    builder.addCase(ChatActions.postMessages.fulfilled, (state, action) => {
      action.payload.moneyspaceIds.forEach((moneyspaceId) => {
        const room = state.rooms.find((item) => item.moneyspaceId === moneyspaceId)
        if (room !== undefined) {
          room.messages.push(action.payload.message)
        }
      })
    })
    builder.addCase(ChatActions.updateReservedMessage.fulfilled, (state, action) => {
      state.reservedMessages = action.payload.reservedMessages
    })
    builder.addCase(ChatActions.deleteReservedMessage.fulfilled, (state, action) => {
      state.reservedMessages = action.payload.reservedMessages
    })
    builder.addCase(ChatActions.loadReservedMessages.fulfilled, (state, action) => {
      state.reservedMessages = action.payload.reservedMessages
    })
  },
})

export default chatSlice
