import { ReactNode, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TreeView from '../../atoms/navigation/TreeView'
import { Moneyspace, MoneyspaceFolder } from '../../../types/Moneyspace'
import TreeItem from '../../atoms/navigation/TreeItem'
import {
  MoneyspaceGroupIcon,
  MoneyspaceIcon,
  PinIcon,
  PinnedIcon,
  SharedMoneyspaceIcon,
} from '../../atoms/display/Icons'
import usePath from '../../../hooks/usePath'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import LabelEllipsis from '../../atoms/display/LabelEllipsis'
import { SessionSelectors } from '../../../store/session/selectors'
import Badge from '../../atoms/display/Badge'
import IconButton from '../../atoms/inputs/IconButton'
import Tooltip from '../../atoms/display/Tooltip'
import useMoneyspaceFolder from '../../../hooks/useMoneyspaceFolder'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import usePermission from '../../../hooks/usePermission'
import useMoneyspace from '../../../hooks/useMoneyspace'

type MoneyspaceTreeViewProps = {
  initialSelectedId?: string
  filteredMoneyspaces?: Moneyspace[]
  onSelectFolder?: (folder?: MoneyspaceFolder) => void
  onSelectMoneyspace?: (moneyspace?: Moneyspace) => void
}

function shared(moneyspace: Moneyspace) {
  return moneyspace.contractor && moneyspace.contractee
}

function BadgedIcon(props: { count?: number; children: ReactNode }) {
  const { count, children } = props
  if (count && count > 0) {
    return (
      <Badge label="" color="error" variant="dot">
        {children}
      </Badge>
    )
  }
  return <>{children}</>
}

function MoneyspaceTreeItemIcon(props: { moneyspace: Moneyspace }) {
  const { getUnreadMessageCount } = useMoneyspace()
  const { moneyspace } = props
  if (shared(moneyspace)) {
    return (
      <BadgedIcon count={getUnreadMessageCount(moneyspace.id)}>
        <SharedMoneyspaceIcon />
      </BadgedIcon>
    )
  }
  return (
    <BadgedIcon count={getUnreadMessageCount(moneyspace.id)}>
      <MoneyspaceIcon />
    </BadgedIcon>
  )
}

function MoneyspaceTreeItem(props: { moneyspace: Moneyspace }) {
  const { moneyspace: moneyspacePage } = usePath()
  const { moneyspacePermissions } = usePermission()
  const companyId = useSelector(SessionSelectors.companyId)
  const user = useSelector(SessionSelectors.user)
  const { moneyspace } = props
  const dispatch = useDispatch()
  const handlePinned = useCallback(() => {
    if (moneyspace.isPinned) {
      dispatch(MoneyspacesActions.unPinnedMoneyspace({ moneyspaceId: moneyspace.id }))
    } else {
      dispatch(MoneyspacesActions.pinnedMoneyspace({ moneyspaceId: moneyspace.id }))
    }
  }, [dispatch, moneyspace.id, moneyspace.isPinned])
  return (
    <TreeItem
      id={moneyspace.id}
      key={moneyspace.id}
      href={moneyspacePage(moneyspace.id)}
      icon={<MoneyspaceTreeItemIcon moneyspace={moneyspace} />}
      minWidth="296px"
      label={
        <FlexRow justify="space-between" grow>
          <Tooltip text={<Label text={moneyspace.name} />}>
            <FlexColumn space={0} grow>
              <LabelEllipsis text={moneyspace.name} width="12rem" />
              {moneyspace.contractee && moneyspace.contractee.id !== companyId && (
                <FlexRow grow>
                  <Label text="発注者" variant="caption" whiteSpace="nowrap" />
                  <LabelEllipsis text={moneyspace.contractee.name} variant="caption" width="8rem" />
                </FlexRow>
              )}
              {moneyspace.contractor && moneyspace.contractor.id !== companyId && (
                <FlexRow grow>
                  <Label text="受注者" variant="caption" whiteSpace="nowrap" />
                  <LabelEllipsis text={moneyspace.contractor.name} variant="caption" width="8rem" />
                </FlexRow>
              )}
            </FlexColumn>
          </Tooltip>
          <IconButton label="ピン留め" disabled={!moneyspacePermissions.pinned(user)} onClick={handlePinned}>
            {moneyspace.isPinned ? <PinnedIcon /> : <PinIcon />}
          </IconButton>
        </FlexRow>
      }
    />
  )
}

function MoneyspaceFolderTreeItem(params: { folder: MoneyspaceFolder }) {
  const { folder } = params
  const { folders, moneyspaces, unreadMessageCount } = useMoneyspaceFolder(folder)
  return (
    <TreeItem
      key={folder.id}
      id={folder.id}
      icon={
        <BadgedIcon count={unreadMessageCount}>
          <MoneyspaceGroupIcon />
        </BadgedIcon>
      }
      minWidth="296px"
      label={<LabelEllipsis text={folder.name} width="12rem" />}
    >
      {folders.map((childFolder) => (
        <MoneyspaceFolderTreeItem folder={childFolder} key={childFolder.id} />
      ))}
      {moneyspaces.map((moneyspace) => (
        <MoneyspaceTreeItem moneyspace={moneyspace} key={moneyspace.id} />
      ))}
    </TreeItem>
  )
}

export default function MoneyspaceTreeView({
  initialSelectedId,
  filteredMoneyspaces,
  onSelectFolder,
  onSelectMoneyspace,
}: MoneyspaceTreeViewProps) {
  const { folders, moneyspaces, findFolder } = useMoneyspaceFolder(null)
  const [selectedId, setSelectedId] = useState(initialSelectedId)
  const handleSelect = useCallback(
    (value: string) => {
      setSelectedId(value)
      const folder = findFolder(value)
      if (onSelectFolder) {
        onSelectFolder(folder ?? undefined)
      }
      const moneyspace = moneyspaces.find((item) => item.id === value)
      if (moneyspace && onSelectMoneyspace) {
        onSelectMoneyspace(moneyspace)
      }
    },
    [findFolder, moneyspaces, onSelectFolder, onSelectMoneyspace]
  )
  return (
    <TreeView selectedId={selectedId} onSelect={handleSelect}>
      {filteredMoneyspaces ? (
        <>
          {filteredMoneyspaces.map((moneyspace) => (
            <MoneyspaceTreeItem moneyspace={moneyspace} key={moneyspace.id} />
          ))}
        </>
      ) : (
        <>
          {folders.map((folder) => (
            <MoneyspaceFolderTreeItem folder={folder} key={folder.id} />
          ))}
          {moneyspaces.map((moneyspace) => (
            <MoneyspaceTreeItem moneyspace={moneyspace} key={moneyspace.id} />
          ))}
        </>
      )}
    </TreeView>
  )
}
