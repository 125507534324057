import { useSelector } from 'react-redux'
import useYup from './useYup'
import { DetailPropConfig } from '../types/Company'
import { CompanySelectors } from '../store/company/selectors'

function emptyToNull(value: string, originalValue: string) {
  if (String(originalValue).trim() === '') {
    return null
  }
  return value
}

export default function useValidationRule() {
  const membershipFeeName = useSelector(CompanySelectors.membershipFeeName)
  const yup = useYup()
  const transactionDetailsRules = (detailPropConfig: DetailPropConfig) => {
    let defaultPropRule = yup
      .string()
      .label(detailPropConfig.defaultProp.name)
      .strict()
      .trim()
      .nullable()
      .transform(emptyToNull)
      .max(10)
    let customProp1Rule = yup
      .string()
      .label(detailPropConfig.customProp1.name)
      .strict()
      .trim()
      .nullable()
      .transform(emptyToNull)
      .max(29)
    let customProp2Rule = yup
      .string()
      .label(detailPropConfig.customProp2.name)
      .strict()
      .trim()
      .nullable()
      .transform(emptyToNull)
      .max(29)

    if (detailPropConfig.defaultProp.required) {
      defaultPropRule = defaultPropRule.required()
    }
    if (detailPropConfig.customProp1.required) {
      customProp1Rule = customProp1Rule.required()
    }
    if (detailPropConfig.customProp2.required) {
      customProp2Rule = customProp2Rule.required()
    }

    return yup.array().of(
      yup.object().shape({
        defaultProp: defaultPropRule,
        customProp1: customProp1Rule,
        customProp2: customProp2Rule,
        unitPrice: yup
          .number()
          .label('単価')
          .max(9900000000)
          .min(-500000000)
          .integer()
          .nullable()
          .transform(emptyToNull)
          .required(),
        quantity: yup
          .number()
          .label('数量')
          .min(-999)
          .max(99999)
          .nullable()
          .transform(emptyToNull)
          .test('thirdDecimal', '小数点以下第3位まで入力可能です', (value) => {
            const values = String(value).split('.')
            if (values.length > 1) {
              return values[1].length <= 3
            }
            return true
          })
          .required(),
        unit: yup.string().label('単位').max(6).strict().trim().nullable().transform(emptyToNull).required(),
        taxBucket: yup
          .number()
          .label('税区分')
          .oneOf([1, 2, 3], '10%、8%、非課税を入力してください')
          .nullable()
          .transform(emptyToNull),
      })
    )
  }
  return {
    companyDomain: yup
      .string()
      .strict()
      .trim()
      .max(64)
      .matches(/^[A-Za-z0-9-._~!$&'()*+,;=:@]+$/),
    email: yup.string().label('メールアドレス').strict().trim().email(),
    password: yup.string().label('パスワード'),
    passwordConfirm: yup
      .string()
      .label('パスワード（確認用）')
      .oneOf([yup.ref('password')], 'パスワードが一致しません'),
    companyName: yup.string().label('法人名 / 個人事業主名').max(64).nullable(),
    representativeName: yup.string().label('代表者氏名').max(64).nullable(),
    representativeRole: yup.string().label('代表者役職').max(64).nullable(),
    zipCode: yup
      .string()
      .label('郵便番号')
      .matches(/^[0-9０-９]{7}$/)
      .nullable()
      .transform(emptyToNull),
    address: yup.string().label('住所').max(128).nullable(),
    phoneNumber: yup
      .string()
      .label('電話番号')
      .matches(/^[0-9-]+$/)
      .nullable()
      .transform(emptyToNull),
    phoneNumberMobile: yup
      .string()
      .label('電話番号（携帯）')
      .matches(/^[0-9-]+$/)
      .nullable()
      .transform(emptyToNull),
    closingDay: yup.number().label('締め日').nullable().transform(emptyToNull),
    paymentDay: yup.number().label('支払日').nullable().transform(emptyToNull),
    bankName: yup.string().label('銀行名').max(32).nullable(),
    bankCode: yup
      .string()
      .label('銀行コード')
      .matches(/^[0-9０-９]{1,4}$/)
      .nullable()
      .transform(emptyToNull),
    branchName: yup.string().label('支店名').max(32).nullable(),
    branchCode: yup
      .string()
      .label('支店コード')
      .matches(/^[0-9０-９]{1,3}$/)
      .nullable()
      .transform(emptyToNull),
    accountNumber: yup
      .string()
      .label('口座番号')
      .matches(/^[0-9]{1,7}$/)
      .nullable()
      .transform(emptyToNull),
    accountOwner: yup
      .string()
      .label('口座名')
      .matches(/^[ｦ-ﾟ0-9A-Z().\-/]*$/)
      .max(64)
      .nullable(),
    userName: yup.string().label('ユーザー名').strict().trim().max(64),
    tagName: yup.string().max(32),
    moneyspaceName: yup.string().label('マネースペース名').trim().max(64),
    documentName: yup.string().label('書類の題名').strict().trim().max(64),
    publishedAt: yup.date().label('発行日').nullable().transform(emptyToNull),
    proviso: yup.string().max(128),
    note: yup.string().max(208).label('備考').nullable().transform(emptyToNull),
    closingDate: yup.date().label('締め日').nullable().transform(emptyToNull),
    paymentDate: yup.date().label('支払期限').nullable().transform(emptyToNull),
    deliveryDateFrom: yup.date().label('工事期間').nullable().transform(emptyToNull),
    deliveryDateTo: yup.date().label('工事期間').nullable().transform(emptyToNull),
    pic: yup.string().label('担当者1').nullable().transform(emptyToNull),
    transactionDetails: transactionDetailsRules,
    transactionName: yup.string().label('取引名').max(35),
    customProp: yup.string(),
    documentTypes: yup
      .array()
      .min(1)
      .of(yup.number())
      .test(
        'moreThan1',
        '1件以上選択してください',
        (items) => !items || items.filter((item) => item !== undefined).length > 0
      ),
    invitee: yup.string().label('取引先').nullable(),
    documentRejectMemo: yup.string().label('却下理由'),
    approvalFlowName: yup.string().label('承認フロー名').strict().trim().max(64),
    membershipFeeRate: (hasMembershipFee: boolean) =>
      yup
        .number()
        .label(`${membershipFeeName}率`)
        .test('moreThan0', `${membershipFeeName}率を入力してください`, (item) => {
          if (item !== undefined && hasMembershipFee) {
            return item > 0
          }
          return true
        }),
    chatMessageText: yup.string().label('メッセージ').max(100),
    invoiceLicenseNumber: yup
      .string()
      .label('事業者登録番号')
      .max(13)
      .matches(/^[0-9０-９]{13}$/)
      .nullable()
      .transform(emptyToNull),
    documentImportItems: yup.array().of(
      yup.object().shape({
        publishedAt: yup
          .string()
          .label('発行日')
          .strict()
          .trim()
          .nullable()
          .transform(emptyToNull)
          .matches(/^[0-9]{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/),
        moneyspaceId: yup.string().label('マネースペース').strict().trim().transform(emptyToNull).nullable().required(),
        contractId: yup.string().label('取引').strict().trim().transform(emptyToNull).nullable().required(),
        contractName: yup.string().label('取引名').strict().max(64).required(),
        note: yup.string().max(208).label('備考').nullable().transform(emptyToNull),
        documentTypes: yup
          .array()
          .min(1)
          .test(
            'moreThan1',
            '1件以上選択してください',
            (items) => !items || items.filter((item) => item !== undefined && item !== true).length > 0
          ),
        documentItems: transactionDetailsRules({
          defaultProp: { name: '', required: false },
          customProp1: { name: '', required: false },
          customProp2: { name: '', required: false },
        }),
      })
    ),
    transactionImportItems: (detailPropConfig: DetailPropConfig) =>
      yup.array().of(
        yup.object().shape({
          publishedAt: yup
            .string()
            .label('発行日')
            .strict()
            .trim()
            .nullable()
            .transform(emptyToNull)
            .matches(/^[0-9]{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/),
          moneyspaceId: yup
            .string()
            .label('マネースペース')
            .strict()
            .trim()
            .transform(emptyToNull)
            .nullable()
            .required(),
          transactionId: yup.string().label('取引').strict().trim().transform(emptyToNull).nullable().required(),
          transactionName: yup.string().label('取引名').strict().trim().max(64).required(),
          requiredPhases: yup
            .object()
            .test('TransactionRequiredPhase', '1つ以上選択してください', (item) => item.phase6 || item.phase7),
          note: yup.string().max(208).label('備考').nullable().transform(emptyToNull),
          details: transactionDetailsRules(detailPropConfig),
        })
      ),
    membershipFeeConditions: yup.array().of(
      yup.object().shape({
        upperAmount: yup.number().label('金額').max(99899999999).integer().nullable().transform(emptyToNull).required(),
        amount: yup.number().integer().label(membershipFeeName).nullable().transform(emptyToNull).required(),
        rate: yup.number().label(membershipFeeName).nullable().transform(emptyToNull).required(),
      })
    ),
    documentSearchConditionName: yup
      .string()
      .label('タイトル')
      .strict()
      .trim()
      .transform(emptyToNull)
      .nullable()
      .max(20)
      .required(),
    transactionCancelMemo: yup.string().label('中止理由'),
    installmentDetails: yup.array().of(
      yup.object().shape({
        amount: yup.number().label('金額').integer().nullable().transform(emptyToNull).required(),
      })
    ),
    externalId: yup
      .string()
      .label('仕入先コード')
      .test('ClientId', '10桁の数字を入力してください', (value) => !value || value.match('^[0-9]{10}$') !== null),
    csvImport: yup
      .object()
      .shape({})
      .test('CsvImportRequiredPhase', '必須のフェーズを1つ以上選択してください', (item) => item.phase6 || item.phase7),
    covenant: yup.object().shape({
      text: yup.string().label('誓約条項').strict().transform(emptyToNull).nullable().max(1000),
    }),
    job: yup.object().shape({
      establishedYear: yup
        .number()
        .label('設立年')
        .nullable()
        .transform(emptyToNull)
        .integer()
        .max(new Date().getFullYear()),
      numberOfEmployees: yup
        .number()
        .label('従業員数')
        .integer()
        .max(1000000)
        .nullable()
        .transform(emptyToNull)
        .required(),
      constructionType1: yup.string().label('第1工種').required(),
      constructionType1Detail: yup.string().label('第1工種詳細').max(20).nullable().transform(emptyToNull).required(),
      constructionType2: yup.string().label('第2工種').nullable().transform(emptyToNull),
      constructionType2Detail: yup.string().label('第2工種詳細').max(20).nullable().transform(emptyToNull),
      constructionType3: yup.string().label('第3工種').nullable().transform(emptyToNull),
      constructionType3Detail: yup.string().label('第3工種詳細').max(20).nullable().transform(emptyToNull),
      siteUrl: yup.string().url().max(1000).nullable().transform(emptyToNull),
      lineId: yup.string().label('LINE ID').required(),
    }),
    jobOffer: yup.object().shape({
      name: yup.string().label('求人名').max(10).required(),
      workingMinutes: yup.number().label('勤務時間').integer().required(),
      salaryOfMonth: yup.number().label('月収').nullable().min(0).max(1000).required(),
      salaryOfMonthAfterOneYear: yup.number().label('1年後の月収').nullable().min(0).max(1000).required(),
      salaryOfMonthAfterThreeYear: yup.number().label('3年後の月収').nullable().min(0).max(1000).required(),
      paymentTiming: yup.number().label('支払いタイミング').integer().required(),
      maximumBonus: yup
        .number()
        .label('賞与の最大金額')
        .nullable()
        .min(0)
        .max(1000)
        .required()
        .test('minimumBonus', '最小の賞与より下回ることはできません', function test(value) {
          if (value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return Number(this.parent.minimumBonus) <= value
          }
          return true
        }),
      minimumBonus: yup
        .number()
        .label('賞与の最小金額')
        .nullable()
        .min(0)
        .max(1000)
        .required()
        .test('minimumBonus', '最大の賞与より上回ることはできません', function test(value) {
          if (value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return Number(this.parent.maximumBonus) >= value
          }
          return true
        }),
      recruitmentMethod: yup.number().label('募集方法').integer().required(),
      interviewLocation: yup.string().label('面接場所').max(30).nullable(),
      interviewerName: yup.string().label('担当者名').max(10).required(),
      appealCondition: yup.number().label('アピールする条件').integer().required(),
    }),
  }
}
