import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const chatSelector = (state: StoreState) => state.chat
export const ChatSelectors = {
  rooms: createSelector([chatSelector], (state) => state.rooms),
  unreadMessages: createSelector([chatSelector], (state) => state.unreadMessages),
  fetchedUnreadMessages: createSelector(chatSelector, (state) => state.fetchedUnreadMessages),
  reservedMessages: createSelector([chatSelector], (state) => state.reservedMessages),
}
