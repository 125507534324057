import { createSlice } from '@reduxjs/toolkit'
import {
  Moneyspace,
  MoneyspaceFolder,
  MoneyspaceGroup,
  MoneyspaceWithLatestMessage,
  Payment,
} from '../../types/Moneyspace'
import { createAsyncAction, GetRedirectPath } from '../actions'
import CompanyRepository from '../../repositories/CompanyRepository'
import { RelatedUser } from '../../types/User'
import { ChatActions } from '../chat'
import { CompanyActions } from '../company'

export type MoneyspacesState = {
  groups: MoneyspaceGroup[]
  folders: MoneyspaceFolder[]
  moneyspaces: Moneyspace[]
  members: {
    moneyspaceId: string
    users: RelatedUser[]
  }[]
  initializedGroups: boolean
  initializedMoneyspace: boolean
  latestMessages: MoneyspaceWithLatestMessage[]
}

export const initialMoneyspacesState: MoneyspacesState = {
  groups: [],
  folders: [],
  moneyspaces: [],
  members: [],
  initializedGroups: false,
  initializedMoneyspace: false,
  latestMessages: [],
}

async function fetchCompanies(
  moneyspaces: Moneyspace[],
  companyRepository: CompanyRepository,
  companyId: string,
  silent?: boolean
) {
  const companies = await companyRepository.loadClients(companyId, silent)
  const own = await companyRepository.loadCompany(companyId, silent)
  if (own) {
    companies.push(own)
  }
  moneyspaces.forEach((ms) => {
    if (ms.contractee) {
      const company = companies.find((item) => item.id === ms.contractee?.id)
      if (company) {
        ms.contractee = {
          ...company,
          ...ms.contractee,
        }
      }
    }
    if (ms.contractor) {
      ms.contractor = companies.find((company) => company.id === ms.contractor?.id)
    }
  })
}

export const MoneyspacesActions = {
  fetchMoneyspaces: createAsyncAction<
    {
      silent?: boolean
    },
    {
      moneyspaces: Moneyspace[]
    }
  >('fetchMoneyspaces', async (params, { moneyspaceRepository, companyRepository }, state) => {
    const moneyspaces = await moneyspaceRepository.loadMoneyspaces(params.silent)
    const { companyId } = state.session
    if (companyId) {
      await fetchCompanies(moneyspaces, companyRepository, companyId, params.silent)
    }

    return { moneyspaces }
  }),
  fetchMoneyspaceSortMessages: createAsyncAction<
    void,
    {
      moneyspaces: MoneyspaceWithLatestMessage[]
    }
  >('fetchMoneyspaceSortMessages', async (params, { moneyspaceRepository, companyRepository }, state) => {
    const moneyspaces = await moneyspaceRepository.loadMoneyspaceSortMessages(false)
    const { companyId } = state.session
    if (companyId) {
      await fetchCompanies(moneyspaces as Moneyspace[], companyRepository, companyId, false)
    }

    return { moneyspaces }
  }),
  fetchMoneyspaceFolders: createAsyncAction<
    void,
    {
      moneyspaceFolders: MoneyspaceFolder[]
    }
  >('fetchMoneyspaceFolders', async (params, { moneyspaceRepository }) => {
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders }
  }),
  createMoneyspaceFolder: createAsyncAction<
    {
      name: string
      parentFolderId: string | null
    },
    {
      moneyspaceFolders: MoneyspaceFolder[]
      folderName: string
    }
  >('createMoneyspaceFolder', async (params, { moneyspaceRepository }) => {
    const folder = await moneyspaceRepository.createFolder(params.name)
    if (params.parentFolderId) {
      await moneyspaceRepository.moveFolder(folder.id, params.parentFolderId)
    }
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders, folderName: params.name }
  }),
  saveMoneyspaceFolder: createAsyncAction<
    {
      id: string
      name: string
    },
    {
      moneyspaceFolders: MoneyspaceFolder[]
      folderName: string
    }
  >('saveMoneyspaceFolder', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.saveFolder(params.id, params.name)
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders, folderName: params.name }
  }),
  moveMoneyspaceFolder: createAsyncAction<
    {
      folder: MoneyspaceFolder
      parentFolderId: string | null
    },
    {
      moneyspaceFolders: MoneyspaceFolder[]
      folderName: string
    }
  >('moveMoneyspaceFolder', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.moveFolder(params.folder.id, params.parentFolderId)
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders, folderName: params.folder.name }
  }),
  deleteMoneyspaceFolder: createAsyncAction<
    {
      id: string
    },
    {
      moneyspaceFolders: MoneyspaceFolder[]
    }
  >('deleteMoneyspaceFolder', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.deleteFolder(params.id)
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders }
  }),
  createMoneyspace: createAsyncAction<
    {
      folderId: string | null
      name: string
      isContractee: boolean
      payment: Payment
      inviteCompanyDomain?: string
      getRedirectPath?: GetRedirectPath<Moneyspace>
      membershipFee: boolean
      alertBillingDeadline: boolean
      fixGracePeriod: number
    },
    {
      moneyspace: Moneyspace
      redirectTo?: string
      moneyspaceFolders: MoneyspaceFolder[]
    }
  >('createMoneyspace', async (params, { moneyspaceRepository, companyRepository }, state) => {
    const newMoneyspace = await moneyspaceRepository.createMoneyspace(
      params.folderId,
      params.name,
      params.isContractee,
      params.payment,
      params.alertBillingDeadline,
      params.fixGracePeriod
    )
    if (params.inviteCompanyDomain) {
      await moneyspaceRepository.inviteMoneyspace(newMoneyspace.id, params.inviteCompanyDomain)
    }
    if (params.membershipFee) {
      await moneyspaceRepository.setMembershipFee(newMoneyspace.id)
    }
    const moneyspace = await moneyspaceRepository.loadMoneyspace(newMoneyspace.id)
    const { companyId } = state.session
    if (companyId) {
      await fetchCompanies([moneyspace], companyRepository, companyId)
    }
    const folders = await moneyspaceRepository.loadFolders()
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(moneyspace) : undefined
    return {
      moneyspace,
      redirectTo,
      moneyspaceFolders: folders,
    }
  }),
  saveMoneyspace: createAsyncAction<
    {
      moneyspace: Moneyspace
    },
    {
      moneyspace: Moneyspace
    }
  >('saveMoneyspace', async (params, { moneyspaceRepository, companyRepository }, state) => {
    const savedMoneyspace = await moneyspaceRepository.saveMoneyspace(params.moneyspace)
    const { companyId } = state.session
    if (companyId) {
      await fetchCompanies([savedMoneyspace], companyRepository, companyId)
    }
    return { moneyspace: savedMoneyspace }
  }),
  moveMoneyspace: createAsyncAction<
    {
      id: string
      folderId: string | null
    },
    {
      moneyspaceFolders: MoneyspaceFolder[]
    }
  >('moveMoneyspace', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.moveMoneyspace(params.id, params.folderId)
    const folders = await moneyspaceRepository.loadFolders()
    return { moneyspaceFolders: folders }
  }),
  deleteMoneyspace: createAsyncAction<
    {
      moneyspace: Moneyspace
      getRedirectPath?: GetRedirectPath<Moneyspace>
    },
    {
      moneyspace: Moneyspace
      redirectTo?: string
    }
  >('deleteMoneyspace', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.deleteMoneyspace(params.moneyspace.id)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(params.moneyspace) : undefined
    return { moneyspace: params.moneyspace, redirectTo }
  }),
  pinnedMoneyspace: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
    }
  >('pinnedMoneyspace', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.pinnedMoneyspace(params.moneyspaceId)
    return { moneyspaceId: params.moneyspaceId }
  }),
  unPinnedMoneyspace: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
    }
  >('unPinnedMoneyspace', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.unPinnedMoneyspace(params.moneyspaceId)
    return { moneyspaceId: params.moneyspaceId }
  }),
  inviteMoneyspace: createAsyncAction<
    {
      moneyspace: Moneyspace
      companyDomain: string
    },
    {
      moneyspace: Moneyspace
      companyDomain: string
    }
  >('inviteMoneyspace', async (params, { moneyspaceRepository }) => {
    await moneyspaceRepository.inviteMoneyspace(params.moneyspace.id, params.companyDomain)
    return params
  }),
  fetchMembers: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
      members: RelatedUser[]
    }
  >('fetchMembers', async (params, { moneyspaceRepository }) => {
    const members = await moneyspaceRepository.loadMembers(params.moneyspaceId)
    return {
      moneyspaceId: params.moneyspaceId,
      members,
    }
  }),
  saveMembers: createAsyncAction<
    {
      moneyspaceId: string
      members: RelatedUser[]
      deleteMembers: RelatedUser[]
    },
    {
      companyId?: string
      sessionUserId?: string
      moneyspaceId: string
      members: RelatedUser[]
    }
  >('saveMembers', async (params, { moneyspaceRepository }, state) => {
    await moneyspaceRepository.saveMembers(params.moneyspaceId, params.members)
    if (params.deleteMembers.length > 0) {
      await moneyspaceRepository.deleteMembers(params.moneyspaceId, params.deleteMembers)
    }
    const sessionUserId = state.session.user?.id
    if (sessionUserId && params.deleteMembers.map((member) => member.id).includes(sessionUserId)) {
      return {
        companyId: state.session.companyId,
        sessionUserId,
        moneyspaceId: params.moneyspaceId,
        members: [],
      }
    }
    const members = await moneyspaceRepository.loadMembers(params.moneyspaceId)
    return {
      companyId: state.session.companyId,
      sessionUserId,
      moneyspaceId: params.moneyspaceId,
      members,
    }
  }),
}

const moneyspacesSlice = createSlice({
  name: 'moneyspaces',
  initialState: initialMoneyspacesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(MoneyspacesActions.fetchMoneyspaces.fulfilled, (state, action) => {
        state.moneyspaces = action.payload.moneyspaces
        state.initializedMoneyspace = true
      })
      .addCase(MoneyspacesActions.fetchMoneyspaceSortMessages.fulfilled, (state, action) => {
        state.latestMessages = action.payload.moneyspaces
      })
      .addCase(MoneyspacesActions.fetchMoneyspaceFolders.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
        state.initializedGroups = true
      })
      .addCase(MoneyspacesActions.createMoneyspaceFolder.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.saveMoneyspaceFolder.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.moveMoneyspaceFolder.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.deleteMoneyspaceFolder.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.createMoneyspace.fulfilled, (state, action) => {
        state.moneyspaces.unshift(action.payload.moneyspace)
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.saveMoneyspace.fulfilled, (state, action) => {
        state.moneyspaces = state.moneyspaces.map((moneyspace) => {
          if (moneyspace.id === action.payload.moneyspace.id) {
            return action.payload.moneyspace
          }
          return moneyspace
        })
      })
      .addCase(MoneyspacesActions.moveMoneyspace.fulfilled, (state, action) => {
        state.folders = action.payload.moneyspaceFolders
      })
      .addCase(MoneyspacesActions.deleteMoneyspace.fulfilled, (state, action) => {
        const { id } = action.payload.moneyspace
        state.moneyspaces = state.moneyspaces.filter((moneyspace) => moneyspace.id !== id)
      })
      .addCase(MoneyspacesActions.pinnedMoneyspace.fulfilled, (state, action) => {
        const { moneyspaceId } = action.payload
        const moneyspace = state.moneyspaces.find((item) => item.id === moneyspaceId)
        if (moneyspace) {
          moneyspace.isPinned = true
        }
      })
      .addCase(MoneyspacesActions.unPinnedMoneyspace.fulfilled, (state, action) => {
        const { moneyspaceId } = action.payload
        const moneyspace = state.moneyspaces.find((item) => item.id === moneyspaceId)
        if (moneyspace) {
          moneyspace.isPinned = false
        }
      })
      .addCase(MoneyspacesActions.fetchMembers.fulfilled, (state, action) => {
        const members = state.members.find((item) => item.moneyspaceId === action.payload.moneyspaceId)
        if (members) {
          members.users = action.payload.members
        } else {
          state.members.push({
            moneyspaceId: action.payload.moneyspaceId,
            users: action.payload.members,
          })
        }
      })
      .addCase(MoneyspacesActions.saveMembers.fulfilled, (state, action) => {
        state.members = state.members.map((item) => {
          if (item.moneyspaceId === action.payload.moneyspaceId) {
            return {
              moneyspaceId: action.payload.moneyspaceId,
              users: action.payload.members,
            }
          }
          return item
        })
        // MSから自分が抜けたときに、MS一覧から抜けたMSを取り除く
        if (
          action.payload.sessionUserId &&
          !action.payload.members.map((member) => member.id).includes(action.payload.sessionUserId)
        ) {
          state.moneyspaces = state.moneyspaces.filter((moneyspace) => moneyspace.id !== action.payload.moneyspaceId)
        }
      })
      .addCase(ChatActions.readMessages.fulfilled, (state, action) => {
        const moneyspace = state.moneyspaces.find((item) => item.id === action.payload.moneyspaceId)
        if (moneyspace) {
          moneyspace.unreadMessageCount = 0
        }
      })
      .addCase(CompanyActions.saveCompany.fulfilled, (state, action) => {
        const moneyspaces = action.payload.moneyspaces.reduce((map, moneyspace) => {
          map[moneyspace.id] = moneyspace
          return map
        }, {} as { [key: string]: Moneyspace })
        state.moneyspaces = state.moneyspaces.map((moneyspace) => {
          const updatedMoneyspace = moneyspaces[moneyspace.id]
          if (updatedMoneyspace) {
            return {
              ...moneyspace,
              invoiceLessActionType: updatedMoneyspace.invoiceLessActionType,
            }
          }
          return moneyspace
        })
      })
  },
})

export default moneyspacesSlice
