import { useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { JobOfferAttributes } from '../../../types/job'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import SelectControl from '../../atoms/inputs/SelectControl'
import useRange from '../../../hooks/useRange'
import Select from '../../atoms/inputs/Select'
import RadioControl from '../../atoms/inputs/RadioControl'
import NumberControl from '../../atoms/inputs/NumberControl'
import { SystemSelectors } from '../../../store/system/selectors'
import Checkbox from '../../atoms/inputs/Checkbox'
import RadioGroup from '../../atoms/inputs/RadioGroup'
import Form from '../../atoms/inputs/Form'
import Button from '../../atoms/inputs/Button'
import useValidationRule from '../../../hooks/useValidationRule'
import useRouter from '../../../hooks/useRouter'

type Props = {
  offer?: JobOfferAttributes | null
  saveLabel: string
  onSave: (data: JobOfferAttributes) => void
}

export default function OfferForm({ offer, saveLabel, onSave }: Props) {
  const { job } = useRouter()
  const hours = useRange(24)
  const [workingMinutes, setWorkingMinutes] = useState<number>(0)
  const consts = useSelector(SystemSelectors.consts)
  const [conditions, setConditions] = useState<number[]>([])
  const [recruitmentMethod, setRecruitmentMethod] = useState<number>(0)
  const [interviewLocationType, setInterviewLocationType] = useState<number>(0)
  const [holidayDaysOfWeek, setHolidayDaysOfWeek] = useState<number[]>([])
  const [bonus, setBonus] = useState<number>(0)
  const rules = useValidationRule()
  const form = useForm<JobOfferAttributes>({
    resolver: yupResolver(rules.jobOffer),
    mode: 'onChange',
    defaultValues: {
      workingMinutes: 0,
      holidayDaysOfWeek: [],
      holidayWorkDays: 0,
      salaryOfMonth: 0,
      salaryOfMonthAfterOneYear: 0,
      salaryOfMonthAfterThreeYear: 0,
      paymentTiming: 1,
      maximumBonus: 0,
      minimumBonus: 0,
      recruitmentMethod: 0,
      appealCondition: 1,
    },
  })
  const { errors } = form.formState

  useEffect(() => {
    if (offer) {
      form.reset(offer)
      form.setValue('workingMinutes', Math.floor(offer.workingMinutes / 60) * 60)
      setWorkingMinutes(offer.workingMinutes % 60)
      setHolidayDaysOfWeek(offer.holidayDaysOfWeek)
      setConditions(offer.conditions)
      setRecruitmentMethod(offer.recruitmentMethod)
      setInterviewLocationType(offer.recruitmentMethod === 0 || offer.interviewLocation === '本社' ? 0 : 1)
      setBonus(offer.bonus ? 1 : 0)
    }
  }, [form, offer])

  useEffect(() => {
    if (bonus === 0) {
      form.setValue('maximumBonus', 0)
      form.setValue('minimumBonus', 0)
    }
  }, [bonus, form])

  useEffect(() => {
    if (interviewLocationType === 0) {
      form.setValue('interviewLocation', '本社')
    } else if (form.getValues('interviewLocation') === '本社') {
      form.setValue('interviewLocation', '')
    }
  }, [interviewLocationType, form])

  useEffect(() => {
    form.watch((value, { name }) => {
      if (name === 'recruitmentMethod' && value.recruitmentMethod !== undefined) {
        setRecruitmentMethod(Number(value.recruitmentMethod))
      }
    })
  }, [form])

  const handleChangeHoldiay = useCallback(
    (checked: boolean, dayOfWeek: number) => {
      if (checked) {
        setHolidayDaysOfWeek([...holidayDaysOfWeek, dayOfWeek])
      } else {
        setHolidayDaysOfWeek(holidayDaysOfWeek.filter((value) => value !== dayOfWeek))
      }
    },
    [holidayDaysOfWeek]
  )

  const handleChangeCondition = useCallback(
    (checked: boolean, conditionId: number) => {
      if (checked) {
        setConditions([...conditions, conditionId])
      } else {
        setConditions(conditions.filter((id) => id !== conditionId))
      }
    },
    [conditions]
  )

  const handleChangeInterviewLocationType = useCallback((value: number | boolean | string) => {
    setInterviewLocationType(Number(value))
  }, [])

  const handleSave = useCallback(
    (params: JobOfferAttributes) => {
      const interviewLocation = () => {
        if (recruitmentMethod === 0) {
          return null
        }
        if (interviewLocationType === 0) {
          return '本社'
        }
        return params.interviewLocation
      }
      onSave({
        ...params,
        workingMinutes: params.workingMinutes + workingMinutes,
        holidayDaysOfWeek,
        conditions,
        interviewLocation: interviewLocation(),
        bonus: bonus === 1,
      })
    },
    [onSave, workingMinutes, holidayDaysOfWeek, conditions, bonus, recruitmentMethod, interviewLocationType]
  )

  return (
    <Form onSubmit={form.handleSubmit(handleSave)}>
      <FlexColumn>
        {' '}
        <FormItem label="求人名">
          {' '}
          <TextField size="sm" register={form.register('name')} error={errors.name?.message} />{' '}
        </FormItem>
        <FormItem label="勤務時間">
          <FlexRow>
            <SelectControl
              name="workingMinutes"
              items={hours.map((value) => ({ value: value * 60, label: `${value}時間` }))}
              control={form.control}
              size="xs"
            />
            <Select
              items={[0, 15, 30, 45].map((value) => ({ value, label: `${value}分` }))}
              value={workingMinutes}
              size="xs"
              onChange={setWorkingMinutes}
            />
          </FlexRow>
        </FormItem>
        <FormItem label="休日の曜日 複数選択可">
          <FlexRow>
            <Checkbox
              label="月"
              checked={holidayDaysOfWeek.includes(1)}
              onChange={(checked) => handleChangeHoldiay(checked, 1)}
            />
            <Checkbox
              label="火"
              checked={holidayDaysOfWeek.includes(2)}
              onChange={(checked) => handleChangeHoldiay(checked, 2)}
            />
            <Checkbox
              label="水"
              checked={holidayDaysOfWeek.includes(3)}
              onChange={(checked) => handleChangeHoldiay(checked, 3)}
            />
            <Checkbox
              label="木"
              checked={holidayDaysOfWeek.includes(4)}
              onChange={(checked) => handleChangeHoldiay(checked, 4)}
            />
            <Checkbox
              label="金"
              checked={holidayDaysOfWeek.includes(5)}
              onChange={(checked) => handleChangeHoldiay(checked, 5)}
            />
            <Checkbox
              label="土"
              checked={holidayDaysOfWeek.includes(6)}
              onChange={(checked) => handleChangeHoldiay(checked, 6)}
            />
            <Checkbox
              label="日"
              checked={holidayDaysOfWeek.includes(7)}
              onChange={(checked) => handleChangeHoldiay(checked, 7)}
            />
            <Checkbox
              label="祝"
              checked={holidayDaysOfWeek.includes(8)}
              onChange={(checked) => handleChangeHoldiay(checked, 8)}
            />
          </FlexRow>
        </FormItem>
        <FormItem label="月当たりの休日出勤日数">
          <SelectControl
            name="holidayWorkDays"
            items={[
              { value: 0, label: 'なし' },
              { value: 1, label: '1日' },
              { value: 2, label: '2日' },
              { value: 3, label: '3日' },
              { value: 4, label: '4日' },
              { value: 5, label: '5日' },
              { value: 6, label: '6日' },
              { value: 7, label: '7日' },
              { value: 8, label: '8日' },
              { value: 9, label: '8日以上' },
            ]}
            control={form.control}
            size="xs"
          />
        </FormItem>
        <FlexRow wrap>
          <FormItem label="入社直後の月収">
            <NumberControl
              control={form.control}
              name="salaryOfMonth"
              size="sm"
              suffix="万円"
              error={errors.salaryOfMonth?.message}
            />
          </FormItem>
          <FormItem label="1年後の月収">
            <NumberControl
              control={form.control}
              name="salaryOfMonthAfterOneYear"
              size="sm"
              suffix="万円"
              error={errors.salaryOfMonthAfterOneYear?.message}
            />
          </FormItem>
          <FormItem label="3年後の月収">
            <NumberControl
              control={form.control}
              name="salaryOfMonthAfterThreeYear"
              size="sm"
              suffix="万円"
              error={errors.salaryOfMonthAfterThreeYear?.message}
            />
          </FormItem>
        </FlexRow>
        <FormItem label="支払いタイミング">
          <RadioControl
            name="paymentTiming"
            row
            items={[
              { value: 1, label: '日払' },
              { value: 2, label: '週払' },
              { value: 3, label: '月払' },
            ]}
            control={form.control}
          />
        </FormItem>
        <FlexRow wrap>
          <FormItem label="賞与">
            <RadioGroup
              row
              items={[
                { value: 0, label: 'なし' },
                { value: 1, label: 'あり' },
              ]}
              value={bonus}
              onChange={(value) => setBonus(Number(value))}
            />
          </FormItem>
          {bonus === 1 && (
            <>
              <FormItem label="昨年賞与を一番多く貰った人の額">
                <NumberControl
                  control={form.control}
                  name="maximumBonus"
                  size="sm"
                  suffix="万円"
                  error={errors.maximumBonus?.message}
                />
              </FormItem>
              <FormItem label="昨年賞与が一番少なかった人の額">
                <NumberControl
                  control={form.control}
                  name="minimumBonus"
                  size="sm"
                  suffix="万円"
                  error={errors.minimumBonus?.message}
                />
              </FormItem>
            </>
          )}
        </FlexRow>
        <FormItem label="その他条件">
          <FlexRow wrap>
            {consts?.jobOfferConditionType.map((type) => (
              <Checkbox
                key={type.id}
                label={type.text}
                checked={conditions.includes(type.id)}
                onChange={(checked) => handleChangeCondition(checked, type.id)}
              />
            ))}
          </FlexRow>
        </FormItem>
        <FormItem label="アピールする条件">
          <SelectControl
            control={form.control}
            name="appealCondition"
            items={
              consts?.jobOfferConditionType.map((type) => ({
                value: type.id,
                label: type.text,
              })) ?? []
            }
            size="md"
            error={errors.appealCondition?.message}
          />
        </FormItem>
        <FormItem label="募集方法">
          <RadioControl
            items={[
              { value: 0, label: '面接なし' },
              { value: 1, label: '面接1回' },
              { value: 2, label: '面接2回以上' },
            ]}
            name="recruitmentMethod"
            control={form.control}
            row
          />
        </FormItem>
        {recruitmentMethod !== 0 && (
          <FormItem label="面接場所">
            <FlexRow align="flex-start">
              <RadioGroup
                value={interviewLocationType}
                items={[
                  { value: 0, label: '本社' },
                  { value: 1, label: 'その他' },
                ]}
                row
                onChange={handleChangeInterviewLocationType}
              />
              {interviewLocationType === 1 && (
                <TextField
                  size="sm"
                  register={form.register('interviewLocation')}
                  error={errors.interviewLocation?.message}
                />
              )}
            </FlexRow>
          </FormItem>
        )}
        <FormItem label="担当者名">
          <TextField size="sm" register={form.register('interviewerName')} error={errors.interviewerName?.message} />
        </FormItem>
        <FlexRow>
          <Button onClick={job} color="secondary">
            キャンセル
          </Button>
          <Button type="submit">{saveLabel}</Button>
        </FlexRow>
      </FlexColumn>
    </Form>
  )
}
