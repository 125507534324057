import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Link from '../../atoms/navigation/Link'
import { Company } from '../../../types/Company'
import { Job } from '../../../types/job'
import Button from '../../atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'
import AreaView from './AreaView'
import { useJobWorkLocationSettingDialog } from '../../../hooks/useDialog'
import { JobSelectors } from '../../../store/job/selectors'

type Props = {
  company: Company
  job: Job | null
}

export default function CompanyView({ company, job }: Props) {
  const { settingsCompany, jobEdit } = useRouter()
  const constructionTypes = useSelector(JobSelectors.constructionTypes)
  const workLocationSettingDialog = useJobWorkLocationSettingDialog()

  return (
    <FlexRow space={4} wrap>
      <FlexColumn width="512px" space={2}>
        <FlexColumn>
          <FlexRow align="center">
            <Label text="企業情報" variant="subtitle" />
            <Button size="sm" height="small" onClick={settingsCompany}>
              編集
            </Button>
          </FlexRow>
          <FlexRow>
            <Label text="企業名" />
            <Label text={company.name} />
          </FlexRow>
          <FlexRow>
            <Label text="代表者名" />
            <Label text={company.representativeName ?? ''} />
          </FlexRow>
          <FlexRow wrap>
            <FlexRow>
              <Label text="住所" />
              <Label text={company.address ?? ''} />
            </FlexRow>
          </FlexRow>
        </FlexColumn>
        <FlexColumn>
          <FlexRow align="center">
            <Label text="勤務地" variant="subtitle" />
            <Button size="sm" height="small" disabled={company.jobId == null} onClick={workLocationSettingDialog.open}>
              編集
            </Button>
          </FlexRow>
          <AreaView />
        </FlexColumn>
      </FlexColumn>
      <FlexColumn width="512px">
        <FlexRow align="center">
          <Label text="企業詳細" variant="subtitle" />
          <Button size="sm" height="small" onClick={jobEdit}>
            編集
          </Button>
        </FlexRow>
        <FlexRow>
          <Label text="設立年" />
          <Label text={job?.establishedYear ?? '未設定'} />
        </FlexRow>
        <FlexRow>
          <Label text="従業員数" />
          <Label text={job?.numberOfEmployees ?? '未設定'} />
        </FlexRow>
        <FlexRow>
          <Label text="第1工種" />
          <Label text={constructionTypes.find((type) => type.id === job?.constructionType1)?.name ?? '未設定'} />
          <Label text={job?.constructionType1Detail ?? ''} />
        </FlexRow>
        <FlexRow>
          <Label text="第2工種" />
          <Label text={constructionTypes.find((type) => type.id === job?.constructionType2)?.name ?? '未設定'} />
          <Label text={job?.constructionType2Detail ?? ''} />
        </FlexRow>
        <FlexRow>
          <Label text="第3工種" />
          <Label text={constructionTypes.find((type) => type.id === job?.constructionType3)?.name ?? '未設定'} />
          <Label text={job?.constructionType3Detail ?? ''} />
        </FlexRow>
        <FlexRow>
          <Label text="ホームページ" />
          {job?.siteUrl ? <Link href={job.siteUrl}>{job.siteUrl}</Link> : <Label text="未設定" />}
        </FlexRow>
        <FlexRow>
          <Label text="LINE ID" />
          {job?.lineId ? <Label text={job.lineId} /> : <Label text="未設定" />}
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}
