import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'
import { JobSelectors } from '../../../store/job/selectors'
import { FlexRow } from '../../atoms/layout/Flex'
import SelectString, { SelectItem } from '../../atoms/inputs/SelectString'
import SelectControl from '../../atoms/inputs/SelectControl'
import { ConstructionType } from '../../../types/job'

type Props<T extends FieldValues> = {
  name: FieldPath<T>
  error?: string
}

function makeConstructionItems(constructionTypes: ConstructionType[]) {
  const items = constructionTypes.map((type) => ({ value: type.id, label: type.name }))
  return [{ value: '', label: '設定なし' }, ...items]
}

export default function ConstructionTypeSelect<T extends FieldValues>({ name, error }: Props<T>) {
  const industryTypes = useSelector(JobSelectors.industryTypes)
  const form = useFormContext()
  const [industryTypeId, setIndustryTypeId] = useState<string>()
  const industryItems = industryTypes.map((type) => ({ value: type.id, label: type.name }))
  const [constructionItems, setConstructionItems] = useState<SelectItem[]>([])

  useEffect(() => {
    const constructionTypeId = form.getValues(name)
    const industryType = industryTypes.find((type) =>
      type.constructionTypes.map((constructionType) => constructionType.id).includes(constructionTypeId)
    )
    if (industryType) {
      setIndustryTypeId(industryType.id)
      const constructionTypes = industryType.constructionTypes ?? []
      setConstructionItems(makeConstructionItems(constructionTypes))
    }
  }, [form, industryTypes, name])

  const handleSelectIndustry = useCallback(
    (typeId: string) => {
      setIndustryTypeId(typeId)
      const constructionTypes = industryTypes.find((type) => type.id === typeId)?.constructionTypes ?? []
      setConstructionItems(makeConstructionItems(constructionTypes))
    },
    [industryTypes]
  )

  return (
    <FlexRow align="flex-start">
      <SelectString value={industryTypeId} items={industryItems} onChange={handleSelectIndustry} size="sm" />
      <SelectControl items={constructionItems} size="sm" control={form.control} name={name} error={error} />
    </FlexRow>
  )
}
